export const se = {
    myGeotabLogin: {
        loginText: 'Logga in',
        registerText: 'Registrera',
    },
    notFound: {
        title: '404 Not Found | Geotab',
        heading: 'Oh no!',
        description: 'The page you were looking for could not be found.',
        description2: 'Try searching or go back to',
        homepageLink: 'our homepage',
        redirectLink: '/',
    },
    salesforce: {
        formLanguage: 'Swedish SE',
    },
    micrositeForm: {
        tooltip: {
            pleaseFillOutThisField: 'Var vänlig fyll i det här fältet',
            pleaseSelectAnItem: 'Välj ett objekt i listan',
        },
        title: 'Kontakta oss',
        subTitle:
            'Låt oss visa hur enkelt det är att använda vår webbaserade programvara och våra telematikenheter för att administrera din vagnpark.',
        countryName: '',
        firstName: 'Förnamn',
        lastName: 'Efternamn',
        company: 'Företag',
        email: 'E-post',
        fleetSize: 'Vagnparkens storlek',
        phone: 'Telefon',
        country: 'Land',
        city: 'Stad',
        submitButton: 'Skicka',
    },
    formPlaceholders: {
        firstNamePlaceholder: 'ange ditt förnamn',
        lastNamePlaceholder: 'ange ditt efternamn',
        companyPlaceholder: 'ange ditt företag',
        emailPlaceholder: 'ange din e-postadress',
        fleetPlaceholder: 'välj en vagnparksstorlek',
        phonePlaceholder: 'ange ditt telefonnummer',
        countryPlaceholder: 'välj ett land',
        cityPlaceholder: 'ange din stad',
        commentsPlaceholder: '',
        fullNamePlaceholder: '',
        messagePlaceholder: '',
        identityPlaceholder: '',
        surnamePlaceholder: '',
        statePlaceholder: '',
        provincePlaceholder: '',
        titlePlaceholder: 'Välj en titel\u2026',
        userTitlePlaceholder: 'Ange din yrkestitel\u2026',
        industryPlaceholder: 'Välj en branschkategori\u2026',
        subIndustryPlaceholder: 'Välj en underbranschkategori\u2026',
    },
    formTranslations: {
        title: 'Titel',
        userTitle: 'Din titel',
        industry: 'Bransch',
        subIndustry: 'Underbransch',
        forceMultiPick: 'Välj minst två alternativ',
        yes: 'Ja',
        no: 'Nej',
    },
    authorBiography: {
        authorOverview: {
            leanMoreAbout: 'Lär dig mera om {authorName}',
        },
    },
    regionSelection: {
        seo: {
            title: 'Val av region',
            description:
                'Välj ditt land eller region för att lära mera om Geotab´s vagnparksmjukvara och fordonsspårningsenheter.',
        },
        title: 'Välj din region',
        regions: {
            northSouthAmerica: 'Nord och Sydamerika',
            europe: 'Europa',
            asiaAustraliaMiddleEast: 'Asien, Australien och Mellanöstern',
        },
        regionChangeText: 'Change language',
    },
    blogTableContent: {
        title: 'Table of contents',
    },
    blogInfo: {
        readMinutes: '5 minuters läsning',
    },
    noBlogsFound: {
        forAuthor: 'Det gick inte att hitta någon blogg av',
        forCategory: 'Det gick inte att hitta någon blogg för',
        forTag: 'Det gick inte att hitta någon resultat med taggen',
        noArticlesFound: 'Inga resultat hittades. Prova att justera filtren.',
    },
    requestDemo: {
        optimizeYourFleetToday: 'Optimize your fleet today',
        formField: {
            leadSource: 'Geotab Demo Form',
            firstName: 'Förnamn',
            lastName: 'Efternamn',
            company: 'Företag',
            email: 'Email',
            selectFleetSize: 'Välj vagnparkstorlek',
            phone: 'Telefon',
            selectCountry: 'Välj land',
            city: 'Stad',
            industry: 'Select Industry',
            consentText:
                'Jag skulle vilja ta emot marknadskommunikation för Geotab.com produkter, tjänster och händelser. Din information hanteras i enlighet med Geotab´s <a href="https://docs.google.com/document/d/1LgdlQDvd2V02h7m1XxV8JlkGh052N1jYr9tHnZkcZf0/edit#heading=h.gjdgxs" rel="noopener" target="_blank">integritetspolicy.</a>',
            requestAFreeDemo: 'Request a free demo',
            formTitle: 'Sign up for a FREE demo',
        },
    },
    contactUsForm: {
        firstName: 'Förnamn',
        lastName: 'Efternamn',
        phone: 'Telefon',
        email: 'Email',
        company: 'Företag',
        fleetSize: 'Fleet Size',
        country: 'Country',
        city: 'Stad',
        comments: 'Questions/Comments:',
        checkboxText:
            'Yes, I would like to receive marketing communications regarding Geotab products, services, and events. Your information will be used in accordance with Geotab’s privacy policy.',
        submit: 'Submit',
        languageCode: 'English Global',
        consentTextEU:
            'Jag skulle vilja ta emot marknadskommunikation för Geotab.com produkter, tjänster och händelser. Din information hanteras i enlighet med Geotab´s integritetspolicy.',
        yes: 'Ja',
        no: 'Nej',
    },
    aboutAuthor: {
        about: 'About',
        currentRole: 'Current role',
        experties: 'Expertise',
        accomplishments: 'Accomplishments',
        education: 'Education',
        publications: 'Publications',
    },
    breadcrumb: {
        home: 'Home',
    },
};
